import styled from "styled-components";

interface CheckmarkProps {
    isChecked: boolean;
    onChange: any;
}

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
    // Hide checkbox visually but remain accessible to screen readers.
    // Source: https://polished.js.org/docs/#hidevisually
    border: 0;
    clip: rect(0 0 0 0);
    clippath: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;

const Icon = styled.svg`
    fill: none;
    stroke: white;
    stroke-width: 4px;
`;

const StyledCheckbox = styled.div<CheckboxProps>`
    display: inline-block;
    width: 16px;
    height: 18px;
    background: ${(props: any) => props.checked ? '#000' : '#E5E5E5'};
    transition: all 150ms;

    ${HiddenCheckbox}:focus + & {
        box-shadow: 0 0 0 3px #9CAAB5;
    }

    ${Icon} {
        visibility: ${(props: any) => props.checked ? 'visible' : 'hidden'}
    }
`;

const CheckboxContainer = styled.div`
    display: flex;
`;

interface CheckboxProps {
    readonly checked: boolean;
}

export function Checkmark({ isChecked, ...props }: CheckmarkProps) {
    return (
        <CheckboxContainer>
            <HiddenCheckbox checked={isChecked} {...props} />
            <StyledCheckbox checked={isChecked}>
                <Icon viewBox="0 0 24 24">
                    <polyline points="20 6 9 17 4 12" />
                </Icon>
            </StyledCheckbox>
        </CheckboxContainer>
    );
}