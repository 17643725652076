import styled from "@emotion/styled";
import { useWindowWidth } from "@react-hook/window-size";
import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import { mobileWidth } from "..";
import { Instance, InstanceStatus, InstanceEntry } from "../components/atoms/instance.entry";
import { SubmittedHeader } from "../components/subscribe.process";

interface PageContainerProps {
    mobile?: boolean;
}

const PageContainer = styled.div(({ mobile }: PageContainerProps) => ({
    background: "#fff",
    margin: `0 ${mobile ? "20px" : "60px"}`
}));

const InstanceList = styled.div({
    display: "flex",
    flexWrap: "wrap"
});

export function Statuses() {

    const [instances, setInstances] = useState<Instance[]>([]);
    const mobile = useWindowWidth() < mobileWidth;

    useEffect(() => {
        async function loadInstances() {
            const response = await fetch("https://api.status.salesforce.com/v1/instances/status/preview?childProducts=false");

            const data = await response.json() as Instance[];
            const errorInstances = data.filter((el: Instance) => el.status !== InstanceStatus.OK && el.status !== InstanceStatus.MAINTENANCE_CORE && el.status  !== InstanceStatus.MAINTENANCE_NONCORE);
            
            setInstances(errorInstances);
        }
        loadInstances();
    }, [])

    return (
        <PageContainer mobile={mobile}>
            <Container>
                { instances.length === 0 && (
                    <SubmittedHeader>
                        All Instances are running OK.
                    </SubmittedHeader>
                ) }
                { instances.length > 0 && (
                    <>
                        <SubmittedHeader>
                            Disrupted Instances.
                        </SubmittedHeader>
                        <InstanceList>
                            { instances.map((el, index) => {
                                return (
                                    <InstanceEntry key={index} instance={el} />
                                );
                            }) }
                        </InstanceList>
                    </>
                ) }
            </Container>
        </PageContainer>
    );
}