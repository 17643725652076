import { useLocalStorage } from "react-use";
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import { Subscription } from "../nav.bar";
import qs from "qs";
import { host } from "../..";
import { useCallback } from "react";

interface FavoriteStarProps {
    entryId: string;
}

export function FavoriteStar({ entryId }: FavoriteStarProps) {
    const [favorites, setFavorites] = useLocalStorage<string[]>("favorites", [], {
        raw: false,
        serializer: (value: string[]) => JSON.stringify(value),
        deserializer: (value: string) => JSON.parse(value),
    });

    const [subscription] = useLocalStorage<Subscription>("subscription", {}, { 
        raw: false,
        serializer: (value: Subscription) => JSON.stringify(value),
        deserializer: (value: string) => JSON.parse(value),
    });

    const updateFavoriteForSubscriber = (method: string) => {
        const query = qs.stringify({
            subscriptionId: subscription!.subscriptionId
        });
        fetch(`${host}/news/${entryId}/favorite?${query}`, {
            method
        });
    }

    const toggleFavorite = useCallback(() => {
        const localFavorites = JSON.parse(localStorage.getItem("favorites")!) as string[];
        console.log(localFavorites);
        if(localFavorites?.includes(entryId)) {
            //remove from favorites
            setFavorites(localFavorites.filter(el => el !== entryId));
            if(subscription?.subscriptionId) {
                updateFavoriteForSubscriber("DELETE")
            }
        } else {
            //add to favorites
            setFavorites([...localFavorites!, entryId]);
            if(subscription?.subscriptionId) {
                updateFavoriteForSubscriber("POST")
            }
        } 
    }, [entryId, setFavorites]);

    return (
        <>
            { favorites?.includes(entryId) ? (
                <StarIcon style={{ color: "#ffd200", cursor: "pointer" }} onClick={toggleFavorite} />
            ) : (
                <StarBorderIcon style={{ cursor: "pointer" }} onClick={toggleFavorite} />
            ) }
        </>
    )
}