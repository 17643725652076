import styled from "@emotion/styled";
import { useWindowWidth } from "@react-hook/window-size";
import dayjs from "dayjs";
import React from "react";
import { mobileWidth } from "../..";
import { NewsEntry } from "../../api/models";
import { ContinueLink } from "../atoms/continue.link";
import { EntryImage } from "../atoms/entry.image";
import { FavoriteStar } from "../atoms/favorite.star";
import { ViewedCounter } from "../atoms/viewed.counter";
import { MobileProps } from "./popular.news";

interface LatestNewProps {
    newsEntry?: NewsEntry;
}

const LatestNewContainer = styled.div(({ mobile }: MobileProps) => ({
    borderBottom: mobile ? "1px solid black" : "",
    margin: mobile ? "20px" : "",
    paddingBottom: mobile ? "20px" : "",
}));

const EntryContent = styled.div`
    display: flex;
    flex-direction: column;
`;

const Heading = styled.p`
    font-family: 'Crimson Pro', serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;

    margin: unset;
    margin-bottom: 12px;
    margin-top: 12px;
    color: #090B0D;
`;

const Text = styled.p`
    font-family: 'Crimson Pro', serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;

    margin: unset;
    color: #2D3942;
    margin-bottom: 1px;
`;

const MetadataContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const WebsiteLink = styled.a`
    text-decoration: none;
    color: unset;

    font-family: 'Crimson Pro', serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: left;
    color: rgb(9, 11, 13);
`;

const AuthorLabel = styled.p`
    margin: unset;
    font-family: 'Crimson Pro', serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: left;
    color: #090B0D;

`;

const StyledHr = styled.hr({
    marginTop: "0.5em",
    marginBottom: "0.5em",
    width: "50%",
    marginLeft: 0,
    borderTop: "1px solid rgb(211 211 211)"
});

export const NewsLabel = styled.p(({ mobile }: MobileProps) => ({
    fontFamily: "'Crimson Pro', serif",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "22px",
    letterSpacing: "0px",
    textAlign: "left",
    color: "#000",

    margin: "unset",
    paddingTop: "16px",
    paddingBottom: "4px",
    marginBottom: mobile ? "50px" : "16px",
    marginLeft: mobile ? "20px" : "",
    borderBottom: mobile ? "1px solid black" : ""
}));

const LinkContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
`;

const FavoriteContainer = styled.div({
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
});

export function LatestNew({ newsEntry }: LatestNewProps) {
    const mobile = useWindowWidth() < mobileWidth;

    return (
        <LatestNewContainer
            mobile={mobile}
        >
            { !newsEntry ? (
                <NewsLabel mobile={mobile}>
                    There is no latest story
                </NewsLabel>
            ) : (
                <>
                    { !mobile && (
                        <NewsLabel>
                            Latest story
                        </NewsLabel>
                    )}
                    { newsEntry.image && (
                        <EntryImage topStory src={newsEntry.image} />
                    ) }
                    <EntryContent>
                        <Heading>{ newsEntry.heading }</Heading>
                        <Text>{ newsEntry.details }</Text>
                        <StyledHr />
                        { mobile ? (
                            <>
                                <MetadataContainer>
                                    <AuthorLabel>
                                        { dayjs(newsEntry.publishedDate).format("MM/DD/YYYY") }  { newsEntry.author && (<span>BY { newsEntry.author }</span>) }
                                    </AuthorLabel>
                                    <WebsiteLink href={newsEntry.website.rootLink} target="_blank">
                                        { newsEntry.website.websiteName }
                                    </WebsiteLink>
                                </MetadataContainer>
                                <LinkContainer>
                                    <FavoriteContainer>
                                        <FavoriteStar entryId={newsEntry.id} />
                                    </FavoriteContainer>
                                    <div>
                                        <ViewedCounter count={newsEntry.viewed} />
                                    </div>
                                    <ContinueLink data={newsEntry} />
                                </LinkContainer>
                            </>
                        ) : (
                            <>
                                <MetadataContainer>
                                    <AuthorLabel>
                                        { dayjs(newsEntry.publishedDate).format("MM/DD/YYYY") }  { newsEntry.author && (<span>BY { newsEntry.author }</span>) }
                                    </AuthorLabel>
                                    <WebsiteLink href={newsEntry.website.rootLink} target="_blank">
                                        { newsEntry.website.websiteName }
                                    </WebsiteLink>
                                    <ContinueLink data={newsEntry} latestLayout />
                                </MetadataContainer>
                                <LinkContainer>
                                    <FavoriteContainer>
                                        <FavoriteStar entryId={newsEntry.id} />
                                    </FavoriteContainer>
                                    <ViewedCounter count={newsEntry.viewed} />
                                    <div></div>
                                </LinkContainer>
                            </>
                        ) }
                        
                    </EntryContent>
                </>
            ) }
        </LatestNewContainer>
    );
}