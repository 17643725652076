import styled from "@emotion/styled";
import React, { useState } from "react";
import { Header } from "./side.bar";
import { useForm } from "react-hook-form";
import { Button, Col, Collapse, Input, Row, Spinner } from "reactstrap";
import { newsletterSchema } from "../validations";
import { yupResolver } from '@hookform/resolvers/yup';
import { StyledErrorMessage } from "./atoms/styledErrorMessage";
import { host } from "..";
import Popper from '@material-ui/core/Popper';
import { Subscription } from "./nav.bar";
import Popover from '@material-ui/core/Popover';
import { useLocalStorage } from "react-use";
import { MobileProps } from "./molecules/popular.news";


interface SubscribeProcessProps {
    subscribed?: boolean;
    setSubscription: any;
    subscription?: Subscription;
    mobile?: boolean;
}

const SubscribeContainer = styled.div({
    marginBottom: "1em"
});

export const StyledRow = styled(Row)({
    marginTop: "1em",
    marginBottom: "1em"
});

export const StyledButton = styled(Button)(({ mobile }: MobileProps) => ({
    backgroundColor: "#000",
    marginBottom: mobile ? "1em" : ""
}));

export const StyledLoader = styled(Spinner)({
    backgroundColor: "#000"
});

export const SubmittedHeader = styled.p`
    font-family: 'Crimson Pro', serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #000;

    margin: unset;
    padding-bottom: 20px;
    margin-bottom: 16px;
`;

const ClickableHeader = styled.p`
    font-family: 'Crimson Pro', serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #000;

    margin: unset;
    padding-bottom: 20px;
    margin-bottom: 16px;
    cursor: pointer;
`;

export interface SubmissionResponse {
    __status: string;
    __msg: string;
}

const StyledPopper = styled(Popper)({
    backgroundColor: "#fff",
    padding: "10px",
    zIndex: 2
});



export function SubscribeProcess({ subscribed, setSubscription, subscription, mobile }: SubscribeProcessProps) {

    const [favorites] = useLocalStorage("favorites", [],  { 
        raw: false,
        serializer: (value: string[]) => JSON.stringify(value),
        deserializer: (value: string) => JSON.parse(value),
    });

    const { register, handleSubmit, errors } = useForm({
        mode: "onBlur",
        criteriaMode: "all",
        resolver: yupResolver(newsletterSchema)
    });

    const onSubmit = async (data: any) => {
        setSubmitting(true);
        data["favorites"] = favorites;
        console.log(data);
        try {
            const response = await fetch(`${host}/service/lead/subscribe`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            });
            console.log(response)
            const body = await response.json() as SubmissionResponse;
            console.log(body);
            if(response.status === 200 && body.__status === "success") {
                setSubscription({
                    subscriptionId: body.__msg,
                    active: true
                });
                setSubmitted(true);
                setSuccessfulSubmission(true);
            } else {
                console.log(body);
                setSubmitted(true);
                setSuccessfulSubmission(false);
            }
        } catch(err) {
            console.log(err);
            setSubmitted(true);
            setSuccessfulSubmission(false);
        }
        
    };

    const [showNames, setShowNames] = useState(false);

    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [successfulSubmission, setSuccessfulSubmission] = useState(false);


    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleOpenSubscribe = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    }
    const open = Boolean(anchorEl);

    // return (
    //     <SubmittedHeader>
    //                             You successfully subscribed for newsletter
    //                         </SubmittedHeader>
    // )

    return (
        <>
            { !subscribed &&  (
                <>
                { !submitted && (
                    <SubscribeContainer>
                    { submitting ? (
                        <>
                            <StyledLoader type="grow" />
                        </>
                    ) : (
                        <>
                            <StyledButton onClick={handleOpenSubscribe} mobile={mobile}>
                                { open ? "Close" : "Subscribe For Newsletter" }
                            </StyledButton>
                            { mobile ? (
                                <>
                                <Collapse isOpen={open}>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                        <Input
                                            type="email"
                                            name="email"
                                            required
                                            placeholder="Email"
                                            onChange={(e) => {
                                                if (e.target.value.length > 1) {
                                                    setShowNames(true);
                                                } else {
                                                    setShowNames(false);
                                                }
                                            }}
                                            innerRef={register}
                                        />
                                        <StyledErrorMessage errors={errors} name="email" as="p" />
                                        {showNames && (
                                            <>
                                                <StyledRow>
                                                    <Col>
                                                        <Input
                                                            type="text"
                                                            required
                                                            name="firstName"
                                                            placeholder="First Name"
                                                            innerRef={register}
                                                        />
                                                        <StyledErrorMessage errors={errors} name="firstName" as="p" />
                                                    </Col>
                                                </StyledRow>
                                                <StyledRow>
                                                    <Col>
                                                        <Input
                                                            type="text"
                                                            required
                                                            name="lastName"
                                                            placeholder="Last Name"
                                                            innerRef={register}
                                                        />
                                                        <StyledErrorMessage errors={errors} name="lastName" as="p" />
                                                    </Col>
                                                </StyledRow>
                                                <StyledButton
                                                    type="submit"
                                                >
                                                    Submit
                                                </StyledButton>
                                            </>
                                        )}
                                    </form>  
                                </Collapse>
                                </>
                            ) : (
                                <StyledPopper 
                                    open={open} 
                                    anchorEl={anchorEl}
                                    placement="bottom"
                                >
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <Input
                                            type="email"
                                            name="email"
                                            required
                                            placeholder="Email"
                                            onChange={(e) => {
                                                if (e.target.value.length > 1) {
                                                    setShowNames(true);
                                                } else {
                                                    setShowNames(false);
                                                }
                                            }}
                                            innerRef={register}
                                        />
                                        <StyledErrorMessage errors={errors} name="email" as="p" />
                                        {showNames && (
                                            <>
                                                <StyledRow>
                                                    <Col>
                                                        <Input
                                                            type="text"
                                                            required
                                                            name="firstName"
                                                            placeholder="First Name"
                                                            innerRef={register}
                                                        />
                                                        <StyledErrorMessage errors={errors} name="firstName" as="p" />
                                                    </Col>
                                                </StyledRow>
                                                <StyledRow>
                                                    <Col>
                                                        <Input
                                                            type="text"
                                                            required
                                                            name="lastName"
                                                            placeholder="Last Name"
                                                            innerRef={register}
                                                        />
                                                        <StyledErrorMessage errors={errors} name="lastName" as="p" />
                                                    </Col>
                                                </StyledRow>
                                                <StyledButton
                                                    type="submit"
                                                >
                                                    Submit
                                                </StyledButton>
                                            </>
                                        )}
                                    </form>
                                </StyledPopper>
                            ) }
                            
                            
                        </>
                        )}

                    </SubscribeContainer>
                ) }
                { submitted && (
                    <>
                        { successfulSubmission ? (
                            <SubmittedHeader>
                                You successfully subscribed for newsletter
                            </SubmittedHeader>
                        ) : (
                            <SubmittedHeader>
                                Subscription is currently unavailable, please contact us on support@addaxcrm.com to subscribe for newsletter
                            </SubmittedHeader>
                        ) }
                    </>
                )}
                </>
            )}
            
            { subscribed && (
                <>
                    {/* { !submitted && (
                        <>
                            { submitting ? (
                                <>
                                    <StyledLoader type="grow" />
                                </>
                            ): (
                                <>
                                    <ClickableHeader onClick={handleOpenSubscribe}>
                                        { open ? "Close" : "Click here to unsubscribe from newsletter" }
                                    </ClickableHeader>
                                    <StyledPopper open={open} anchorEl={anchorEl} >
                                        <SubmittedHeader style={{ textAlign: "center" }}>
                                            Are you sure?
                                        </SubmittedHeader>
                                        <StyledButton onClick={onUnsubscribe}>
                                            Unsubscribe
                                        </StyledButton>
                                    </StyledPopper>
                                </>
                            ) }
                        </>
                    ) } */}
                    { submitted && (
                        <SubmittedHeader>
                            You successfully subscribed for newsletter
                        </SubmittedHeader>
                    ) }
                </>
            ) }
        </>
    );
}