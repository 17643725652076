import styled from "@emotion/styled";
import React from "react";
import { WebsiteEntry } from "../../api/models";
import { Checkmark } from "./checkmark";


interface ResourceEntryProps {
    website: WebsiteEntry;
    resourceSelected: boolean;
    handleCheckboxChange: any;
}

const ResourceContainer = styled.label`
    display: flex;
    width: 100%;
    cursor: pointer;
`;

const ResourceImage = styled.img`
    border-radius: 100%;
    width: 22px;
    height: 22px;
    margin-right: 8px;
`;

const ResourceName = styled.p`
    font-family: 'Crimson Pro', serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: left;

    margin: unset;
    margin-top: auto;
    margin-bottom: auto;
`;

const ResourceCheck = styled.div`
    margin-right: 10px;
`;

export function ResourceEntry({ website, resourceSelected, handleCheckboxChange }: ResourceEntryProps) {

    return (
        <ResourceContainer>
            {/* <ResourceImage src={website.iconsrc} /> */}
            <ResourceCheck>
                <Checkmark
                    isChecked={resourceSelected}
                    onChange={handleCheckboxChange}
                />
            </ResourceCheck>
            <ResourceName>
                { website.websiteName }
            </ResourceName>
            
        </ResourceContainer>
    );
}