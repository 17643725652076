import React from "react";
import ReactDOM from 'react-dom';
import App from "./App";
import { ReactPWAInstallProvider } from "react-pwa-install";

import 'bootstrap/dist/css/bootstrap.min.css';

const dev = false;

export const mobileWidth = 800;

export const host = dev ? "" : "https://api.softwirenews.com";

export const siteHost = dev ? "http://localhost:3000" : "https://softwirenews.com"

ReactDOM.render(
    <React.StrictMode>
        <ReactPWAInstallProvider enableLogging>
            <App />
        </ReactPWAInstallProvider>
        
    </React.StrictMode>,
    document.getElementById('root')
);
