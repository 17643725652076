import styled from '@emotion/styled';
import VisibilityIcon from '@material-ui/icons/Visibility';
import React from 'react';

interface ViewedCounterProps {
    count: number;
}

const StyledContainer = styled.div({
    display: "flex",
    alignItems: "center",
    height: "100%",
    justifyContent: "center"
})

export function ViewedCounter({ count }: ViewedCounterProps) {
    return (
        <StyledContainer>
            <VisibilityIcon fontSize="small" style={{ color: "grey", marginRight: "5px" }} />
            { new Intl.NumberFormat().format(count)}
        </StyledContainer>
    )
}