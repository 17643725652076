import styled from "@emotion/styled";
import React from "react";
import { WebsiteEntry } from "../api/models";
import { SourceDef } from "../pages/news";
import { ResourceEntry } from "./molecules/resource.entry";
import { SubscribeProcess } from "./subscribe.process";

const SideBarContainer = styled.div`
    width: 330px;
`;

const SideBarContent = styled.div`
`;

export const Header = styled.p`
    font-family: 'Crimson Pro', serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #000;

    margin: unset;
    padding-bottom: 20px;
    border-bottom: 1px solid #E5E5E5;
    margin-bottom: 16px;
`;

const ResourceContainer = styled.div`

`;

interface SideBarProps {
    availableResources: WebsiteEntry[];
    selectedSources: SourceDef;
    setSelectedSources: any;
}


export function SideBar({ availableResources, selectedSources, setSelectedSources, }: SideBarProps) {

    return (
        <SideBarContainer>
            <SideBarContent>
                <ResourceContainer>
                    { availableResources.map(resource => (
                        <ResourceEntry 
                            key={resource.websiteName} 
                            website={resource} 
                            resourceSelected={selectedSources[resource.id]} 
                            handleCheckboxChange={(evt: any) => setSelectedSources( {...selectedSources, [resource.id]: evt.target.checked })}
                        />
                    )) }
                </ResourceContainer>
            </SideBarContent>
        </SideBarContainer>
    )
}