import styled from "@emotion/styled";

interface InstanceEntryProps {
    instance: Instance;
}

export interface Instance {
    key: string;
    location?: string;
    status: InstanceStatus;
}

export enum InstanceStatus {
    OK = "OK",
    MAJOR_INCIDENT_CORE = "MAJOR_INCIDENT_CORE",
    MINOR_INCIDENT_CORE = "MINOR_INCIDENT_CORE",
    MAINTENANCE_CORE = "MAINTENANCE_CORE",
    MAJOR_INCIDENT_NONCORE = "MAJOR_INCIDENT_NONCORE",
    MINOR_INCIDENT_NONCORE = "MINOR_INCIDENT_NONCORE",
    MAINTENANCE_NONCORE = "MAINTENANCE_NONCORE"
}

const InstanceContainer = styled.div({
    minWidth: "100px",
    height: "30px",
    borderRadius: "4px",
    display: "flex",
    padding: "2px 10px",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid red",
    color: "red",
    marginRight: "20px",
    "&:hover": {
        backgroundColor: "bisque"
    },
    whiteSpace: "nowrap"
});

const StyledLink = styled.a({
    "&:hover" : {
        textDecoration: "none",
    }
})

export function InstanceEntry({ instance }: InstanceEntryProps) {
    return (
        <StyledLink href={`https://status.salesforce.com/instances/${instance.key}`} target="_blank">
            <InstanceContainer>
                {instance.key}
            </InstanceContainer>
        </StyledLink>
        
    );
}