import styled from "@emotion/styled";
import dayjs from "dayjs";
import React from "react";
import { NewsEntry } from "../../api/models";
import { ContinueLink } from "../atoms/continue.link";
import { EntryImage } from "../atoms/entry.image";
import { ViewedCounter } from "../atoms/viewed.counter";
import { FavoriteStar } from "../atoms/favorite.star";
import { useWindowWidth } from "@react-hook/window-size";
import { mobileWidth } from "../..";



interface NewsViewProps {
    data: NewsEntry;
}

interface EntryContainerProps {
    mobile?: boolean;
}

const EntryContainer = styled.div(({ mobile }: EntryContainerProps) => ({
    display: "grid",
    paddingBottom: "20px",
    gridTemplateColumns: "1fr",
    gridGap: "10px",
    margin: mobile ? "20px" : "40px",
    borderBottom: "1px solid rgb(151 151 151)"
}));

const EntryContent = styled.div`
    display: flex;
    flex-direction: column;
`;

const LinkContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
`;



const Heading = styled.p`
    font-family: 'Crimson Pro', serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;

    margin: unset;
    margin-bottom: 12px;
    margin-top: 12px;
    color: #090B0D;
`;

const Text = styled.p`
    font-family: 'Crimson Pro', serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;

    margin: unset;
    color: #2D3942;
    margin-bottom: 1px;
`;

const MetadataContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const WebsiteLink = styled.a`
    text-decoration: none;
    color: unset;

    font-family: 'Crimson Pro', serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: left;
    color: rgb(9, 11, 13);
`;

const AuthorLabel = styled.p`
    margin: unset;
    font-family: 'Crimson Pro', serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: left;
    color: #090B0D;

`;

const StyledHr = styled.hr({
    marginTop: "0.5em",
    marginBottom: "0.5em",
    width: "50%",
    marginLeft: 0,
    borderTop: "1px solid rgb(211 211 211)"
});

const FavoriteContainer = styled.div({
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
});

export function NewsView({ data }: NewsViewProps) {
    
    const mobile = useWindowWidth() < mobileWidth;

    return (
        <EntryContainer
            mobile={mobile}
        >
            { data.image && (
                <EntryImage src={data.image} />
            ) }
            <EntryContent>
                <Heading>{ data.heading }</Heading>
                <Text>{ data.details }</Text>
                <StyledHr />
                <MetadataContainer>
                    <AuthorLabel>
                        { dayjs(data.publishedDate).format("MM/DD/YYYY") }  { data.author && (<span>BY { data.author }</span>) }
                    </AuthorLabel>
                    <WebsiteLink href={data.website.rootLink} target="_blank">
                        { data.website.websiteName }
                    </WebsiteLink>
                </MetadataContainer>
                <LinkContainer>
                    <FavoriteContainer>
                        <FavoriteStar entryId={data.id} />
                    </FavoriteContainer>
                    <div>
                        <ViewedCounter count={data.viewed} />
                    </div>
                    <ContinueLink data={data} />
                </LinkContainer>
            </EntryContent>
        </EntryContainer>
    );
}