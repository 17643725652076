import styled from "@emotion/styled";
import React, { useState } from "react";
import { NewsEntry, WebsiteEntry } from "../api/models";
import { SourceDef } from "../pages/news";
import { LatestNew } from "./molecules/latest.new";
import { SideBar } from "./side.bar";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Box, createStyles, Theme, withStyles } from "@material-ui/core";
import { PopularNews } from "./molecules/popular.news";
import { FavoriteNews } from "./molecules/favorite.news";
import { useWindowWidth } from "@react-hook/window-size";
import { mobileWidth } from "..";

interface TopNewsProps {
    latest?: NewsEntry;
    availableResources: WebsiteEntry[];
    sources?: SourceDef;
    setSources: any;
}

const TopNewsContainer = styled.div({
    display: "grid",
    gridTemplateColumns: "3fr 2fr",
    gridGap: "40px",
    margin: "0 40px",
    paddingBottom: "20px",
    borderBottom: "2px solid black"
});

const AntTabs = withStyles({
    root: {
        borderBottom: '1px solid #e8e8e8',
    },
    indicator: {
        backgroundColor: '#000',
    },
})(Tabs);

const AntTab = withStyles((theme: Theme) =>
    createStyles({
        root: {
            textTransform: 'none',
            minWidth: 72,
            fontWeight: theme.typography.fontWeightRegular,
            marginRight: theme.spacing(1),
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Crimson Pro, serif',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
            '&:hover': {
                color: '#000',
                opacity: 1,
            },
            '&$selected': {
                color: '#000',
                fontWeight: theme.typography.fontWeightMedium,
            },
            '&:focus': {
                color: '#000',
            },
        },
        selected: {},
    }),
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

interface StyledTabProps {
    label: string;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

const StyledBox = styled.div({
    paddingTop: "24px",
});

const TabsMobileContainer = styled.div({
    paddingBottom: "20px"
});

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-prevent-tabpanel-${index}`}
        aria-labelledby={`scrollable-prevent-tab-${index}`}
        {...other}
      >
        {value === index && (
          <StyledBox>
            {children}
          </StyledBox>
        )}
      </div>
    );
  }

export function TopNews({ latest, sources, setSources, availableResources }: TopNewsProps) {
    const [tabValue, setTabValue] = useState(0);

    const handleChange = (ev: any, newValue: number) => {
        setTabValue(newValue);
    }

    const mobile = useWindowWidth() < mobileWidth;

    if(mobile) {
        return (
            <TabsMobileContainer>
                <AntTabs 
                    value={tabValue} 
                    onChange={handleChange} 
                    aria-label="tabs" 
                    style={{ margin: "0 20px" }} 
                    variant="scrollable"
                    scrollButtons="off"
                >
                    <AntTab label="Latest Story" />
                    <AntTab label="Most Popular" />
                    <AntTab label="Favorite" />

                </AntTabs>
                <TabPanel value={tabValue} index={0}>
                    <LatestNew newsEntry={latest} />
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <PopularNews selectedSources={sources!} />
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                    <FavoriteNews />
                </TabPanel>
            </TabsMobileContainer>
        );
    }

    return (
        <TopNewsContainer>
            <LatestNew newsEntry={latest} />
            <div>
                <AntTabs 
                    value={tabValue} 
                    onChange={handleChange} 
                    aria-label="tabs" 
                    variant="scrollable"
                    scrollButtons="off"
                >
                    
                    <AntTab label="Most Popular" />
                    <AntTab label="Favorite" />
                    <AntTab label="Resources" />
                </AntTabs>
                <TabPanel value={tabValue} index={0}>
                    <PopularNews selectedSources={sources!} />
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <FavoriteNews />
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                    <SideBar
                        availableResources={availableResources}
                        selectedSources={sources!}
                        setSelectedSources={setSources}
                    />
                </TabPanel>
            </div>
            
        </TopNewsContainer>
    );
}