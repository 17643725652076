import React from "react";
import "./App.css";
import { NavBar } from "./components/nav.bar";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Statuses } from "./pages/statuses";
import { News } from "./pages/news";
import { NewsEntryPage } from "./pages/news.entry.page";



function App() {

    return (
        
            <div>
                <Router>
                    <NavBar />
                    <Switch>
                        <Route path="/statuses" component={Statuses} />
                        {/* <Route path="/entry/:id" component={NewsEntryPage} /> */}
                        <Route>
                            <News />
                        </Route>
                    </Switch>
                </Router>
            </div>
    );
}

export default App;
