import styled from "@emotion/styled";
import { useCallback, useState } from "react";

interface EntryImageProps {
    src: string;
    topStory?: boolean;
}

interface StyledImageProps {
    topStory?: boolean;
}

const StyledImage = styled.img(({ topStory }: StyledImageProps) => ({
    width: topStory ?  "unset" : "100%",
    maxWidth: "100%"
}));

export function EntryImage({ src, topStory }: EntryImageProps) {

    const [errored, setErrored] = useState(false);

    const handleError = useCallback(() => {
        setErrored(true);
    }, []);


    if(errored) {
        return null;
    }


    return (
        <StyledImage src={src} onError={handleError} topStory={topStory} />
    );
}