import styled from "@emotion/styled";
import { useWindowWidth } from "@react-hook/window-size";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useLocalStorage } from "react-use";
import { NavLink, Nav, NavItem, Input } from "reactstrap";
import { StyledButton, StyledLoader, StyledRow, SubmissionResponse, SubmittedHeader, SubscribeProcess } from "./subscribe.process";
import { SourceDef } from "../pages/news";
import { host, mobileWidth } from "..";
import { WebsiteEntry } from "../api/models";
import { ResourceEntry } from "./molecules/resource.entry";
import { Accordion, AccordionDetails, AccordionSummary, Button, Divider, Drawer, IconButton, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Menu } from "@material-ui/icons";
import ClearIcon from "@material-ui/icons/Clear";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { unsubscribeSchema } from "../validations";
import { StyledErrorMessage } from "./atoms/styledErrorMessage";
import { useReactPWAInstall } from "react-pwa-install";

const NavContainer = styled.div`
    grid-area: nav;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    position: sticky;
    top: 0;
    width: 100%;
    background: #fff;
    z-index: 1;
`;

const LogoText = styled.p`
    font-family: 'Crimson Pro', serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;

    color: #000;
    margin: 1rem;
`;

const SloganText = styled.p`
    font-family: 'Crimson Pro', serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: left;

    color: #000;
    margin: 1rem;
    margin-top: 0;
`;

const LogoContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const LogoImg = styled.img`
    margin-right: 24px;
`;

const LinksContainer = styled.div({
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
});

interface StyledLinkProps {
    active?: boolean;
}

const StyledLink = styled(NavLink)(({ active }: StyledLinkProps) => ({
    color: "#000",
    borderBottom: active ? "2px solid #000" : "none"
}));

const DrawerContainer = styled.div({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    "> *": {
        margin: "1em 0"
    }
});

const CloseContainer = styled.div({
    width: "100%",
    margin: 0
});

export type Subscription = {
    subscriptionId?: string;
    active?: boolean;
}

export function NavBar() {
    const location = useLocation();
    const pageName = location.pathname === "/statuses" ? "statuses" : "home";
    const mobile = useWindowWidth() < mobileWidth;

    const [subscription, setSubscription] = useLocalStorage<Subscription>("subscription", {}, { 
        raw: false,
        serializer: (value: Subscription) => JSON.stringify(value),
        deserializer: (value: string) => JSON.parse(value),
    });

    const [sources, setSources] = useLocalStorage<SourceDef>("resources", {}, { 
        raw: false,
        serializer: (value: SourceDef) => JSON.stringify(value),
        deserializer: (value: string) => JSON.parse(value),
    });

    const { register, handleSubmit, errors } = useForm({
        mode: "onBlur",
        criteriaMode: "all",
        resolver: yupResolver(unsubscribeSchema)
    });

    const [availableResources, setAvailableResources] = useState<WebsiteEntry[]>([]);

    const [openMenu, setOpenMenu] = useState(false);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleOpenUnSubscribe = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    }
    const open = Boolean(anchorEl);
    const [submitting, setSubmitting] = useState(false);
    const [successfulUnsubscribe, setSuccessfulUnsubscribe] = useState(false);

    useEffect(() => {
        async function loadResources() {
            const response = await fetch(`${host}/sites/`);
            const body = await response.json();
            setAvailableResources(body.sites);
    
            if(Object.keys(sources!).length === 0) {
                setSources(body.sites.map((el: WebsiteEntry) => el.id).reduce((p: SourceDef, c: string) => {return { ...p, [c]: true }}, {}));
            }
        }
        if(mobile) {
            loadResources();
        }
    }, []);

    const onUnsubscribe = async(data: any) => {
        setSubmitting(true);
        try {
            const response = await fetch(`${host}/service/lead/unsubscribe`, {
                method: "POST",
                body: JSON.stringify({
                    subscriptionId: subscription?.subscriptionId,
                    email: data.email
                })
            });

            const body = await response.json() as SubmissionResponse;
            if(response.status === 200 && body.__status === "success") {
                console.log("success");
            }
            // setSubmitted(true);
            setSubscription({
                subscriptionId: subscription?.subscriptionId,
                active: false
            });
        } catch (err) {
            console.log(err);
            // setSubmitted(true);
            
        }
        setSuccessfulUnsubscribe(true);
        setSubmitting(false);
    }

    const { pwaInstall, supported, isInstalled } = useReactPWAInstall();

    const handleInstallClick = () => {
        pwaInstall({
            title: "SoftWireNews App",
            description: "Install SoftWireNews to your home screen as an app"
        });
    }

    if(mobile) {
        return (
            <>
            
            <NavContainer>
                <Button onClick={() => setOpenMenu(true)}>
                    <Menu />
                </Button>
                <Drawer open={openMenu} onClose={() => setOpenMenu(false)}>
                    <DrawerContainer>
                        <CloseContainer>
                            <IconButton onClick={() => setOpenMenu(false)}>
                                <ClearIcon  />
                            </IconButton>
                            
                        </CloseContainer>
                        { supported() && !isInstalled() && (
                            <StyledButton onClick={handleInstallClick} mobile={mobile}>
                                Install App to your device
                            </StyledButton>
                        ) }
                        
                        <Nav>
                            <NavItem>
                                <StyledLink href="/" active={pageName === "home"}>SF News</StyledLink>
                            </NavItem>
                            <NavItem>
                                <StyledLink href="/statuses" active={pageName === "statuses"}>SF Status</StyledLink>
                            </NavItem>
                        </Nav>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>Resources</Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{ flexDirection: "column" }}>
                                { availableResources.map(resource => (
                                    <ResourceEntry 
                                        key={resource.websiteName} 
                                        website={resource} 
                                        resourceSelected={sources![resource.id]} 
                                        handleCheckboxChange={(evt: any) => setSources( {...sources, [resource.id]: evt.target.checked })}
                                    />
                                )) }
                            </AccordionDetails>
                        </Accordion>
                        { subscription?.active ? (
                            <>
                            { submitting ? (
                                <StyledLoader type="grow" />
                            ) : (
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center"
                                }} >
                                    <StyledButton onClick={handleOpenUnSubscribe} mobile={mobile}>
                                        { open ? "Close" : "Unsubscribe For Newsletter" }
                                    </StyledButton>
                                    { open && (
                                        <form onSubmit={handleSubmit(onUnsubscribe)}>
                                            <StyledRow>
                                                <Input
                                                    type="email"
                                                    name="email"
                                                    required
                                                    placeholder="Email"
                                                    innerRef={register}
                                                />
                                            </StyledRow>
                                            
                                            <StyledErrorMessage errors={errors} name="email" as="p" />
                                            <StyledRow>
                                                <StyledButton
                                                    type="submit"
                                                >
                                                    Submit
                                                </StyledButton>
                                            </StyledRow>
                                            
                                        </form>
                                    ) }
                                </div>
                            ) } 
                            </>
                        ) : (
                            <>
                                {successfulUnsubscribe && (
                                    <SubmittedHeader>
                                        You successfully unsubscribed for newsletter
                                    </SubmittedHeader>
                                )}
                            </>
                        ) }
                        
                    </DrawerContainer>
                </Drawer>
                <LogoContainer>
                    <LogoText>
                        SoftWireNews
                    </LogoText>
                    <SloganText>
                        Salesforce.com news in one place
                    </SloganText>
                </LogoContainer>
                <LogoContainer>
                    { subscription?.active ? (
                        <>
                            { submitting ? (
                                <StyledLoader type="grow" />
                            ) : (
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center"
                                }} >
                                    <StyledButton onClick={handleOpenUnSubscribe} mobile={mobile}>
                                        { open ? "Close" : "Unsubscribe For Newsletter" }
                                    </StyledButton>
                                    { open && (
                                        <form onSubmit={handleSubmit(onUnsubscribe)}>
                                            <StyledRow>
                                                <Input
                                                    type="email"
                                                    name="email"
                                                    required
                                                    placeholder="Email"
                                                    innerRef={register}
                                                />
                                            </StyledRow>
                                            
                                            <StyledErrorMessage errors={errors} name="email" as="p" />
                                            <StyledRow>
                                                <StyledButton
                                                    type="submit"
                                                >
                                                    Submit
                                                </StyledButton>
                                            </StyledRow>
                                            
                                        </form>
                                    ) }
                                </div>
                            ) } 
                        </>
                        ) : (
                            <>
                                {successfulUnsubscribe && (
                                    <SubmittedHeader>
                                        You successfully unsubscribed for newsletter
                                    </SubmittedHeader>
                                )}
                            </>
                        ) }
                    { !subscription?.active && !successfulUnsubscribe && (
                        <SubscribeProcess
                            subscription={subscription}
                            subscribed={subscription?.active}
                            setSubscription={setSubscription}
                        />
                    ) }
                </LogoContainer>
            </NavContainer>
            </>
        );
    }
        

    return (
        <NavContainer>
            <LinksContainer>
                <Nav>
                    <NavItem>
                        <StyledLink href="/" active={pageName === "home"}>SF News</StyledLink>
                    </NavItem>
                    <NavItem>
                        <StyledLink href="/statuses" active={pageName === "statuses"}>SF Status</StyledLink>
                    </NavItem>
                </Nav>
            </LinksContainer>
            <LogoContainer>
                <LogoText>
                    SoftWireNews
                </LogoText>
                <SloganText>
                    Salesforce.com news in one place
                </SloganText>
            </LogoContainer>
            <LogoContainer>
            { subscription?.active ? (
                        <>
                            { submitting ? (
                                <StyledLoader type="grow" />
                            ) : (
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center"
                                }} >
                                    <StyledButton onClick={handleOpenUnSubscribe} mobile={mobile}>
                                        { open ? "Close" : "Unsubscribe For Newsletter" }
                                    </StyledButton>
                                    { open && (
                                        <form onSubmit={handleSubmit(onUnsubscribe)}>
                                            <StyledRow>
                                                <Input
                                                    type="email"
                                                    name="email"
                                                    required
                                                    placeholder="Email"
                                                    innerRef={register}
                                                />
                                            </StyledRow>
                                            
                                            <StyledErrorMessage errors={errors} name="email" as="p" />
                                            <StyledRow>
                                                <StyledButton
                                                    type="submit"
                                                >
                                                    Submit
                                                </StyledButton>
                                            </StyledRow>
                                            
                                        </form>
                                    ) }
                                </div>
                            ) } 
                        </>
                        ) : (
                            <>
                                {successfulUnsubscribe && (
                                    <SubmittedHeader>
                                        You successfully unsubscribed for newsletter
                                    </SubmittedHeader>
                                )}
                            </>
                        ) }
                    { !subscription?.active && !successfulUnsubscribe && (
                        <SubscribeProcess
                            subscription={subscription}
                            subscribed={subscription?.active}
                            setSubscription={setSubscription}
                        />
                    ) }
            </LogoContainer>
        </NavContainer>
    );
}