import styled from "@emotion/styled";
import { useWindowWidth } from "@react-hook/window-size";
import qs from "qs";
import React from "react";
import { useEffect, useState } from "react";
import { host, mobileWidth } from "../..";
import { NewsEntry } from "../../api/models";
import { SourceDef } from "../../pages/news";
import { NewsView } from "./news.view";

interface PopularNewsProps {
    selectedSources?: SourceDef;
}

export interface MobileProps {
    mobile?: boolean;
}

const PopularContainer = styled.div(({ mobile }: MobileProps) => ({
    borderLeft: mobile ? "" : "1px solid #ccc",
    minHeight: mobile ? "" : "385px"
}));


export function PopularNews({ selectedSources }: PopularNewsProps) {

    const [popularNews, setPopularNews] = useState<NewsEntry[]>([]);

    const mobile = useWindowWidth() < mobileWidth;

    useEffect(() => {
        async function loadPopularNews() {
            const query = qs.stringify({
                limit: 1,
                sources: Object.keys(selectedSources!).filter(el => selectedSources![el])
            });
            const response = await fetch(`${host}/news/popular?${query}`);
            const body = await response.json();
            if(body.news) {
                setPopularNews(body.news);
            }
            
        }
        loadPopularNews();
    }, [selectedSources]);

    return (
        <PopularContainer
            mobile={mobile}
        >
            { popularNews.map((el, index) => (
                <NewsView data={el} key={`${el.link}${index}`} />
            )) }
        </PopularContainer>
    );
}