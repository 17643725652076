import styled from "@emotion/styled";
import { host } from "../..";
import { NewsEntry } from "../../api/models";

interface ContinueLinkProps {
    data: NewsEntry;
    latestLayout?: boolean;
}

interface StyledLinkProps {
    latestLayout?: boolean;
}

const StyledLink = styled.a(({ latestLayout }: StyledLinkProps) => ({
    color: "#000",
    fontFamily: "'Crimson Pro', serif",
    fontSize: "14px",
    fontStyle: "italic",
    fontWeight: 900,
    lineHeight: latestLayout ? "14px" : "30px",
    letterSpacing: "0px",
    textAlign: "right"
}));

export function ContinueLink({ data, latestLayout }: ContinueLinkProps) {
    const handleContinueClick = (entryId: string) => {
        fetch(`${host}/news/${entryId}/entryview`, {
            method: "POST"
        });
    }

    return (
        <StyledLink latestLayout={latestLayout} href={data.link} target="_blank" onClick={() => {
            handleContinueClick(data.id);
        }}>
            continue
        </StyledLink>
    )
}