import styled from "@emotion/styled";
import { useWindowWidth } from "@react-hook/window-size";
import dayjs from "dayjs";
import qs from "qs";
import React, { useState, useCallback, useEffect } from "react";
import { useLocalStorage } from "react-use";
import { host, mobileWidth } from "..";
import { NewsEntry, WebsiteEntry } from "../api/models";
import { Subscription } from "../components/nav.bar";
import { NewsList } from "../components/news.list";
import { TopNews } from "../components/top.news";

interface AppContainerProps {
    mobile?: boolean;
}

const AppContainer = styled.div(({ mobile }: AppContainerProps) => ({
    display: "flex",
    flexDirection: "column",
    margin: `0 ${mobile ? "20px" : "60px"}`
}));

export type SourceDef = {
    [key: string]: boolean;
}

export function News() {

    const [sources, setSources] = useLocalStorage<SourceDef>("resources", {}, { 
        raw: false,
        serializer: (value: SourceDef) => JSON.stringify(value),
        deserializer: (value: string) => JSON.parse(value),
    });

    const [, setFavorites] = useLocalStorage("favorites", [],  { 
        raw: false,
        serializer: (value: string[]) => JSON.stringify(value),
        deserializer: (value: string) => JSON.parse(value),
    });

    const [subscription] = useLocalStorage<Subscription>("subscription", {}, { 
        raw: false,
        serializer: (value: Subscription) => JSON.stringify(value),
        deserializer: (value: string) => JSON.parse(value),
    });

    const mobile = useWindowWidth() < mobileWidth;

    const [loading, setLoading] = useState(false);
    const [news, setNews] = useState<NewsEntry[]>([]);
    const [lastLoadedPublishedAt, setLastLoadedPublishedAt] = useState<string | undefined>(undefined);
    const [hasMore, setHasMore] = useState(false);
    const [availableResources, setAvailableResources] = useState<WebsiteEntry[]>([]);
    const [latestStory, setLatestStory] = useState<NewsEntry>();

    const loadNews = useCallback(async(removeLastPublished) => {
            const query = qs.stringify({
                limit: 20,
                sources: Object.keys(sources!).filter(el => sources![el]),
                lastLoadedPublishedAt: removeLastPublished ? undefined : lastLoadedPublishedAt
            });
            const response = await fetch(`${host}/news/entries?${query}`);
            const body = await response.json();

            if(!latestStory || (body.news.length > 0 && dayjs(latestStory?.publishedDate).isBefore(dayjs(body.news[0].publishedDate)))) {
                setLatestStory(body.news.shift());
            }

            setNews((prevNews) => prevNews.length === 0 ? [...body.news] : [...prevNews, ...body.news]);
            body.lastPublishedAt && setLastLoadedPublishedAt(body.lastPublishedAt);
            setHasMore(body.availableMore);
            
    }, [sources, lastLoadedPublishedAt, latestStory])

    useEffect(() => {
        async function loadFavorites() {
            const query = qs.stringify({
                limit: 1,
                subscriptionId: subscription?.subscriptionId ?? ""
            });

            const response = await fetch(`${host}/news/favorite?${query}`);
            const body = await response.json();
            if(body?.news?.length) {
                setFavorites(body.news.map((el: any) => el.id));
            }
        }

        if(subscription?.subscriptionId) {
            loadFavorites();
        }
    }, [subscription?.active, subscription?.subscriptionId]);

    useEffect(() => {

        async function loadResources() {
            const response = await fetch(`${host}/sites/`);
            const body = await response.json();
            setAvailableResources(body.sites);
    
            if(Object.keys(sources!).length === 0) {
                setSources(body.sites.map((el: WebsiteEntry) => el.id).reduce((p: SourceDef, c: string) => {return { ...p, [c]: true }}, {}));
            }
        }

        setLoading(true);
        loadResources();
        setLoading(false);

    }, []);

    useEffect(() => {
        setLastLoadedPublishedAt(undefined);
        setLoading(true);
        setNews([]);
        
        loadNews(true);
        setLoading(false);
    }, [sources]);

    return (
        <AppContainer mobile={mobile}>
            <TopNews 
                latest={latestStory} 
                availableResources={availableResources}
                sources={sources}
                setSources={setSources}
            />
            <NewsList news={news} loading={loading} hasMore={hasMore} loadMoreNews={loadNews} />
            
        </AppContainer>
    )
}