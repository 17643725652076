import styled from "@emotion/styled";
import { useWindowWidth } from "@react-hook/window-size";
import qs from "qs";
import React, { useEffect, useState } from "react";
import { useLocalStorage } from "react-use";
import { host, mobileWidth } from "../..";
import { NewsEntry } from "../../api/models";
import { Subscription } from "../nav.bar";
import { NewsLabel } from "./latest.new";
import { NewsView } from "./news.view";
import { MobileProps } from "./popular.news";

const FavoriteContainer = styled.div(({ mobile }: MobileProps) => ({
    borderLeft: mobile ? "" : "1px solid #ccc",
    minHeight: mobile ? "" : "385px"
}));

export function FavoriteNews() {

    const [subscription] = useLocalStorage<Subscription>("subscription", {}, {
        raw: false,
        serializer: (value: Subscription) => JSON.stringify(value),
        deserializer: (value: string) => JSON.parse(value),
    });
    const [favorites] = useLocalStorage<string[]>("favorites", [], {
        raw: false,
        serializer: (value: string[]) => JSON.stringify(value),
        deserializer: (value: string) => JSON.parse(value),
    });

    const [favoriteNews, setFavoriteNews] = useState<NewsEntry[]>([]);

    const mobile = useWindowWidth() < mobileWidth;

    useEffect(() => {
        async function loadFavoriteNews() {
            let query;
            if(!subscription?.subscriptionId) {
                query = qs.stringify({
                    limit: 1,
                    ids: favorites
                });
            } else {
                query = qs.stringify({
                    limit: 1,
                    subscriptionId: subscription.subscriptionId
                });
            }
            const response = await fetch(`${host}/news/favorite?${query}`);
            const body = await response.json();
            if(body.news.length) {
                setFavoriteNews([body.news[0]]);
            }
            
        }
        loadFavoriteNews();
    }, [favorites, subscription]);

    return (
        <FavoriteContainer
            mobile={mobile}
        >
            { favoriteNews.length === 0 && (
                <NewsLabel mobile={mobile} style={{ textAlign: "center" }}>
                    Mark stories to save into your favorites
                </NewsLabel>
            ) }
            { favoriteNews.map((el, index) => (
                <NewsView data={el} key={`${el.link}${index}`} />
            )) }
        </FavoriteContainer>
    );
}