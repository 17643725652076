import React from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import { NewsView } from "./molecules/news.view";
import InfiniteScroll from "react-infinite-scroll-component";
import Masonry from "react-masonry-css";
import { NewsEntry } from "../api/models";
import styled from "styled-components";


interface NewsListProps {
    news: NewsEntry[];
    loading: boolean;
    hasMore: boolean;
    loadMoreNews: any;
}

interface LoadingProps {
    isLoading: boolean;
}

const Container = styled.div<LoadingProps>`
    background: #fff;
    ${ props => props.isLoading && `
        display: flex;
        justify-content: center;
        align-items: center;
    ` }
    
`;

const breakpointColumnsObj = {
    default: 3,
    1600: 3,
    1200: 2,
    800: 1
  };

export function NewsList({ news, loading, hasMore, loadMoreNews }: NewsListProps) {
    return (
        <Container id="scrollableDiv" isLoading={loading} >
            { loading ? (
            <CircularProgress />
        ) : (
            <InfiniteScroll
                hasMore={hasMore}
                next={loadMoreNews}
                dataLength={news.length}
                loader={<h4 style={{ textAlign: 'center' }}>Loading...</h4>}
                endMessage={
                    <p style={{ textAlign: 'center' }}>
                        <b>Yay! You have seen it all</b>
                    </p>
                }
                // scrollableTarget="scrollableDiv"
            >
                <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column"
                >
                    { news.map((el, index) => (
                        <NewsView data={el} key={`${el.link}${index}`} />
                    )) }
                </Masonry>
            </InfiniteScroll>
        ) }
        </Container>
        
    );
}