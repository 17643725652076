import * as yup from "yup";

export const newsletterSchema = yup.object().shape({
    email: yup.string().email().required("Required"),
    firstName: yup.string().max(40, "Value is too long").required("Required"),
    lastName: yup.string().max(80, "Value is too long").required("Required")
});

export const unsubscribeSchema = yup.object().shape({
    email: yup.string().email().required("Required")
});